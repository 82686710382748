import(/* webpackMode: "eager", webpackExports: ["SignupDialog","UpgradeDialog"] */ "/vercel/path0/apps/nextjs/src/components/dialog/sign-up.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Gleap"] */ "/vercel/path0/apps/nextjs/src/components/external/gleap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/apps/nextjs/src/components/external/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductFruitsProvider"] */ "/vercel/path0/apps/nextjs/src/components/external/product-fruits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppLoader"] */ "/vercel/path0/apps/nextjs/src/components/lotties/app-loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SyncAnnouncement"] */ "/vercel/path0/apps/nextjs/src/components/organisations/sync-announcement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/nextjs/src/components/theme/Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventsWebSocketProvider"] */ "/vercel/path0/apps/nextjs/src/components/websocket/socket-io-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmationDialogProvider"] */ "/vercel/path0/apps/nextjs/src/hoc/confirmation-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/nextjs/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCProvider"] */ "/vercel/path0/apps/nextjs/src/trpc/react.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
