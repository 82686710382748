"use client";
import Lottie from "react-lottie";

import * as loader from "./trigify-loading-3.json";

export const AppLoader = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={width}
      width={height}
    />
  );
};
