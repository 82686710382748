import { OnboardingStep } from "@prisma/client";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { z } from "zod";

export const shortId = () => {
  return nanoid(20);
};

export const countNetNewResults = <TResult extends { updated?: Date }>(results: TResult[]) => {
  return results?.reduce((acc, result) => {
    // count how many have been updated in last 1 day
    const lastUpdated = dayjs(result.updated);
    if (lastUpdated.isAfter(dayjs().subtract(1, "day"))) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const clearLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveToLocalStorage = <TValue extends Record<string, unknown>>(key: string, value: TValue) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <TValue>(key: string): TValue | null => {
  try {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value) as TValue;
  } catch (error) {
    return null;
  }
};

export const mapOnboardingEnumToStep = (onboardingStep: OnboardingEnum) => {
  switch (onboardingStep) {
    case "about-you":
      return OnboardingStep.ABOUT_YOU;
    case "connect-slack":
      return OnboardingStep.CONNECT_SLACK;
    case "connect-integrations":
      return OnboardingStep.CONNECT_INTEGRATIONS;
    case "create-organisation":
      return OnboardingStep.CREATE_ORGANISATION;
    case "finished":
      return OnboardingStep.FINISHED;
    default:
      return OnboardingStep.ABOUT_YOU;
  }
};

export const onboardingEnum = z.enum([
  "about-you",
  "connect-slack",
  "connect-integrations",
  "create-organisation",
  "finished",
]);

export type OnboardingEnum = z.infer<typeof onboardingEnum>;

export const generateIdFromKeywords = (keywords: string[]) => {
  // take all distinct keywords and join them in alphabetical order with a hyphen
  return keywords.sort().join("-");
};
