"use client";
import { Box, Button, Card, Chip, Grid, Stack, Typography, useTheme } from "@mui/material";
import { TrigifyModal } from "./dialog";
import { Check, CheckCircle } from "@untitled-ui/icons-react";
import { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { green } from "@mui/material/colors";
import { ChatToSalesBtn } from "~/_pages/subscriptions/chat-to-sales";
import Link from "next/link";
import { errorToast } from "../theme/hooks";
import { api } from "~/trpc/react";
import { useUser } from "@clerk/nextjs";
import { useGetUserPlan } from "~/app/hooks/useGetUserPlan";
import { LoadingButton } from "../buttons/LoadingButton";
import { getFromLocalStorage, saveToLocalStorage } from "~/server/utils";
import dayjs from "dayjs";
import { UserSubscription } from "@prisma/client";
import posthog from "posthog-js";
import { PostHogEvents } from "@trigify/services/posthog";
import { SubscriptionService } from "@trigify/services/subscriptions";
import { ButtonSpinner } from "../loaders/button-spinner";
import { formatDecimalToCurrency } from "../cards/subscription-card";
import { subscribeToEvent } from "~/utils/events";

const readablePlan = (plan: UserSubscription) => {
  switch (plan) {
    case UserSubscription.FREEMIUM:
      return "Free";
    case UserSubscription.SCALE:
      return "Scale";
    case UserSubscription.GROWTH:
      return "Growth";
    case UserSubscription.CUSTOM:
      return "Custom";
    case UserSubscription.SOCIAL_SIGNAL:
      return "Social Signal";
    default:
      return "Free";
  }
};

const getNumberOfProfiles = (productId: string) => {
  switch (productId) {
    case process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID:
      return 50;
    case process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID:
      return 20;
    case process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRODUCT_ID:
      return 10;
    default:
      return 1;
  }
};

const getNumberOfUsers = (productId: string) => {
  switch (productId) {
    case process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID:
      return 10;
    case process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID:
      return 5;
    case process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRODUCT_ID:
      return 3;
    default:
      return 1;
  }
};

type UpgradeDialogProps = {
  open: boolean;
  onClose: () => void;
  userPlan?: UserSubscription;
  customerId?: string;
  upgradeMessage: string;
};

export const SignupDialog = () => {
  const { user } = useUser();
  const { userPlan, freeTrialInfo, isLoading } = useGetUserPlan();
  const email = user?.emailAddresses[0]?.emailAddress;
  const userId = user?.id;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { isPending: subscribing, mutateAsync: subscribeToPlan } = api.settings.subscribeToPlan.useMutation();

  const isInLastTwoDaysOfTrial = freeTrialInfo?.timeRemaining >= 0 && freeTrialInfo?.timeRemaining <= 3;

  const isFreeUser = userPlan?.subscription_plan === UserSubscription.FREEMIUM;

  const handleGetStarted = async () => {
    if (!email || !userId) return;
    try {
      const { url } = await subscribeToPlan({
        customerId: userPlan?.stripe_customer_id!,
        priceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PRICE_ID!,
        productId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID!,
        phonePriceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PHONE_PRICE_ID!,
        emailPriceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_EMAIL_PRICE_ID!,
        email: email,
        redirectUrl: `${window.location.origin}/settings/manage-plan`,
      });

      posthog.capture(PostHogEvents.UPGRADED_PLAN, {
        email,
        plan: UserSubscription.SCALE,
        via: "dialog",
      });

      if (!url) {
        errorToast("Something went wrong. Please try again later.");
        return;
      }
      window.open(url, "_blank");
    } catch (error) {
      const e = error as Error;
      errorToast(e.message);
    }
  };

  const handleCheckVisibility = useCallback(() => {
    if (isLoading) return;

    const lastOpened = getFromLocalStorage("signupDialogLastOpened") as {
      dateOpened: string;
    };
    if (isInLastTwoDaysOfTrial || isFreeUser) {
      if (dayjs().diff(dayjs(lastOpened?.dateOpened), "days") >= 1 || !lastOpened) {
        setOpen(true);
        saveToLocalStorage("signupDialogLastOpened", {
          dateOpened: dayjs().toISOString(),
        });
      }
    }
  }, [isFreeUser, isInLastTwoDaysOfTrial, isLoading]);

  useEffect(() => {
    handleCheckVisibility();
  }, [handleCheckVisibility]);

  return (
    <TrigifyModal
      onClose={() => setOpen(false)}
      sx={{
        "& .MuiPaper-root": {
          width: 700,
        },
      }}
      actions={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            mt: 2,
          }}
        >
          <Box>
            <ChatToSalesBtn />
          </Box>
          <Box sx={{ gap: 2, display: "flex" }}>
            <Link href="/settings/manage-plan" passHref>
              <Button
                sx={{
                  height: "48px",
                }}
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                View Plans
              </Button>
            </Link>
            <LoadingButton
              loading={subscribing}
              sx={{
                height: "40px",
              }}
              variant="contained"
              onClick={handleGetStarted}
            >
              Upgrade
            </LoadingButton>
          </Box>
        </Box>
      }
      open={open}
      title="Your free trial is ending soon"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Image
              src="/assets/hand-drawn-arrow.svg"
              alt="Upgrade"
              width={135}
              height={144}
              style={{
                position: "absolute",
                top: 100,
                left: 200,
              }}
            />
            <Stack
              sx={{
                width: 266,
                gap: 4,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: 30,
                }}
              >
                Unlock the full power of{" "}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: 30,
                    display: "inline",
                    color: "primary.main",
                  }}
                >
                  Trigify.
                </Typography>
              </Typography>
              <Typography variant="body1">
                Upgrade to Scale to continue using all the features of your current subscription or view our other
                plans.
              </Typography>
              <Card
                variant="outlined"
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: 3,
                }}
                style={{
                  minWidth: 266,
                  width: 266,
                }}
              >
                <Stack
                  sx={{
                    gap: 2,
                  }}
                >
                  <Chip
                    label="Upcoming plan"
                    variant="outlined"
                    sx={{
                      height: 24,
                      width: "fit-content",
                      fontSize: 12,
                    }}
                  />
                  <Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      Free
                    </Typography>
                    <Typography variant="body1">$0/month</Typography>
                  </Stack>
                  <Typography>Access to your Free account ends in {freeTrialInfo.timeRemaining ?? 0} days.</Typography>
                </Stack>
                <Stack
                  sx={{
                    gap: 1,
                    my: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Check width={16} />
                    View Only
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Check width={16} />
                    No LinkedIn Sync
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Check width={16} />
                    Single user
                  </Box>
                </Stack>
              </Card>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card
            variant="outlined"
            elevation={0}
            style={{
              minWidth: 266,
              width: 266,
            }}
            sx={{
              p: 3,
              borderRadius: 3,
              border: (theme) => `2px solid ${theme.palette.tertiary.main}`,
              background: (theme) => theme.palette.tertiary.light,
            }}
          >
            <Stack
              sx={{
                gap: 2,
              }}
            >
              <Chip
                label="Save 20% with yearly plan"
                variant="outlined"
                sx={{
                  color: green[900],
                  border: (theme) => `1px solid ${theme.palette.success.main}`,
                  background: green[50],
                  height: 24,
                  width: "fit-content",
                  fontSize: 12,
                }}
              />
              <Stack>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Scale
                </Typography>
                <Typography variant="body1">$549/month</Typography>
              </Stack>
              <Typography>Continue using the powerful features of Trigify with a subscription.</Typography>
            </Stack>
            <Stack gap={1} my={2}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                Advanced features
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                Advanced reporting
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                5+ users
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                20 Social profiles
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                Integrations
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CheckCircle
                  style={{
                    color: theme.palette.tertiary.main,
                  }}
                />
                Automations
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </TrigifyModal>
  );
};

export const UpgradeDialog = () => {
  const { userPlan: user } = useGetUserPlan();

  const userPlan = user?.subscription_plan!;

  const currentPlanPriceId = SubscriptionService.getNextPlan(userPlan)?.current?.priceId;
  const currentPlanProductId = SubscriptionService.getNextPlan(userPlan)?.current?.productId;
  const currentUsernameAmount = getNumberOfProfiles(currentPlanProductId!);

  const nextPlanPriceId = SubscriptionService.getNextPlan(userPlan)?.next?.priceId;
  const nextPlanProductId = SubscriptionService.getNextPlan(userPlan)?.next?.productId;
  const nextEmailPriceId = SubscriptionService.getNextPlan(userPlan)?.next?.emailPriceId;
  const nextPhonePriceId = SubscriptionService.getNextPlan(userPlan)?.next?.phonePriceId;

  const nextSubscription = SubscriptionService.getNextPlan(userPlan)?.next?.userSubscription;

  const { data: currentData, isPending: isLoadingCurrentData } = api.settings.getStripePlan.useQuery(
    {
      priceId: currentPlanPriceId!,
      productId: currentPlanProductId!,
    },
    {
      enabled: !!currentPlanPriceId && !!currentPlanProductId,
    },
  );

  const { data: priceData, isPending: isLoadingPriceData } = api.settings.getStripePlan.useQuery(
    {
      priceId: nextPlanPriceId!,
      productId: nextPlanProductId!,
    },
    {
      enabled: !!nextPlanPriceId && !!nextPlanProductId,
    },
  );

  const email = user?.email;
  const userId = user?.user_id;
  const customerId = user?.stripe_customer_id;

  const theme = useTheme();
  const { isPending: subscribing, mutateAsync: subscribeToPlan } = api.settings.subscribeToPlan.useMutation();

  const nextPlanCost = priceData?.price?.unit_amount;
  const nextPlanName = priceData?.product?.name;

  const currentPlanCost = currentData?.price?.unit_amount;
  const currentPlanName = currentData?.product?.name;

  const numberOfProfiles = getNumberOfProfiles(nextPlanProductId!);
  const numberOfUsers = getNumberOfUsers(nextPlanProductId!);

  const currentNumberOfEmails = SubscriptionService.determineEmailLimit(user?.subscription_plan!);
  const currentNumberOfPhones = SubscriptionService.determinePhoneLimit(user?.subscription_plan!);

  const nextNumberOfEmails = SubscriptionService.determineEmailLimit(nextSubscription!);
  const nextNumberOfPhones = SubscriptionService.determinePhoneLimit(nextSubscription!);

  const isFreeUser = userPlan === UserSubscription.FREEMIUM;

  const handleGetStarted = async () => {
    if (!email || !userId) return;
    try {
      const { url } = await subscribeToPlan({
        customerId: customerId,
        priceId: nextPlanPriceId!,
        productId: nextPlanProductId!,
        phonePriceId: nextEmailPriceId!,
        emailPriceId: nextPhonePriceId!,
        email: email,
        redirectUrl: `${window.location.origin}/settings/manage-plan`,
      });

      posthog.capture(PostHogEvents.UPGRADED_PLAN, {
        email,
        plan: UserSubscription.SCALE,
        via: "dialog",
      });

      if (!url) {
        errorToast("Something went wrong. Please try again later.");
        return;
      }
      window.open(url, "_blank");
    } catch (error) {
      const e = error as Error;
      errorToast(e.message);
    }
  };

  const [open, setOpen] = useState(false);
  const [upgradeMessage, setUpgradeMessage] = useState("");

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    // listen for the event to open the dialog
    subscribeToEvent("openUpgradeDialog", (e) => {
      setOpen(true);
      setUpgradeMessage(e.message);
    });
  });

  return (
    <TrigifyModal
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          width: 700,
        },
      }}
      actions={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            mt: 2,
          }}
        >
          <Box>
            <ChatToSalesBtn />
          </Box>
          <Box sx={{ gap: 2, display: "flex" }}>
            <Link href="/settings/manage-plan" passHref>
              <Button
                sx={{
                  height: "48px",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                View Plans
              </Button>
            </Link>
            <LoadingButton
              loading={subscribing}
              sx={{
                height: "40px",
              }}
              variant="contained"
              onClick={handleGetStarted}
            >
              Upgrade
            </LoadingButton>
          </Box>
        </Box>
      }
      open={open}
      title="Upgrade your plan"
    >
      <Grid container spacing={2}>
        {isLoadingPriceData ? (
          <ButtonSpinner />
        ) : (
          <>
            <Grid item xs={6}>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Image
                  src="/assets/hand-drawn-arrow.svg"
                  alt="Upgrade"
                  width={135}
                  height={144}
                  style={{
                    position: "absolute",
                    top: 100,
                    left: 200,
                  }}
                />
                <Stack
                  sx={{
                    width: 266,
                    gap: 4,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: 30,
                    }}
                  >
                    Unlock the full power of{" "}
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: 30,
                        display: "inline",
                        color: "primary.main",
                      }}
                    >
                      Trigify.
                    </Typography>
                  </Typography>
                  <Typography variant="body1">{upgradeMessage}</Typography>
                  <Card
                    variant="outlined"
                    elevation={0}
                    sx={{
                      p: 3,
                      borderRadius: 3,
                    }}
                    style={{
                      minWidth: 266,
                      width: 266,
                    }}
                  >
                    <Stack
                      sx={{
                        gap: 2,
                      }}
                    >
                      <Chip
                        label="Current plan"
                        variant="outlined"
                        sx={{
                          height: 24,
                          width: "fit-content",
                          fontSize: 12,
                        }}
                      />
                      <Stack>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          {readablePlan(userPlan)}
                        </Typography>
                        <Typography variant="body1">{formatDecimalToCurrency(currentPlanCost!)}/month</Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        gap: 1,
                        my: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Check width={16} />
                        {currentUsernameAmount} Social profiles
                      </Box>
                    </Stack>
                    <Stack
                      sx={{
                        gap: 1,
                        my: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Check width={16} />
                        {currentNumberOfEmails} Emails
                      </Box>
                    </Stack>
                    <Stack
                      sx={{
                        gap: 1,
                        my: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Check width={16} />
                        {currentNumberOfPhones} Phones
                      </Box>
                    </Stack>
                  </Card>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Card
                variant="outlined"
                elevation={0}
                style={{
                  minWidth: 266,
                  width: 266,
                }}
                sx={{
                  p: 3,
                  borderRadius: 3,
                  border: (theme) => `2px solid ${theme.palette.tertiary.main}`,
                  background: (theme) => theme.palette.tertiary.light,
                }}
              >
                <Stack
                  sx={{
                    gap: 2,
                  }}
                >
                  <Chip
                    label="Save 20% with yearly plan"
                    variant="outlined"
                    sx={{
                      color: green[900],
                      border: (theme) => `1px solid ${theme.palette.success.main}`,
                      background: green[50],
                      height: 24,
                      width: "fit-content",
                      fontSize: 12,
                    }}
                  />
                  <Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {nextPlanName}
                    </Typography>
                    <Typography variant="body1">
                      {nextPlanCost ? `${formatDecimalToCurrency(nextPlanCost)}/month` : ""}
                    </Typography>
                  </Stack>
                  <Typography>Continue using the powerful features of Trigify with a subscription.</Typography>
                </Stack>
                <Stack gap={1} my={2}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    {numberOfProfiles} Social profiles
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    {nextNumberOfEmails} Emails
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    {nextNumberOfPhones} Phones
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    Advanced features
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    Advanced reporting
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    {numberOfUsers}+ users
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    Integrations
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: theme.palette.tertiary.main,
                      }}
                    />
                    Automations
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </TrigifyModal>
  );
};
