import { Button } from "@mui/material";
import { CHAT_TO_SALES_LINK } from "~/consts";

export const ChatToSalesBtn = ({
  fullWidth = true,
}: {
  fullWidth?: boolean;
}) => {
  const handleTalkToSales = () => {
    void window.open(CHAT_TO_SALES_LINK, "_blank");
  };
  return (
    <Button
      onClick={handleTalkToSales}
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        background: (theme) => theme.palette.common.white,
        height: "48px",
      }}
    >
      Chat to sales
    </Button>
  );
};
