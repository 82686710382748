"use client";
import { Box, Button, Card, FormLabel, MenuItem, Select, Skeleton, Stack, Typography, styled } from "@mui/material";
import { UserSubscription } from "@prisma/client";
import { useEffect, useState } from "react";
import { SubscriptionService } from "@trigify/services/subscriptions";
import { api } from "~/trpc/react";
import { LoadingButton } from "../buttons/LoadingButton";
import type { Subscription } from "~/_pages/subscriptions/Subscriptions";
import { ArrowNarrowDown } from "@untitled-ui/icons-react";

export const SeniorCredits = () => {
  const amount = String(SubscriptionService.determineCreditValue(UserSubscription.SENIOR));
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <FormLabel>Credit amount</FormLabel>
      <Select
        disabled
        variant="outlined"
        fullWidth
        label=""
        sx={{
          height: "48px",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.23)",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        }}
        value={amount}
      >
        <MenuItem value={amount}>10,000</MenuItem>
      </Select>
    </Box>
  );
};
export const SeniorPlusCredits = () => {
  const amount = String(SubscriptionService.determineCreditValue(UserSubscription.SENIOR_PLUS));
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <FormLabel>Credit amount</FormLabel>
      <Select
        disabled
        variant="outlined"
        fullWidth
        label=""
        sx={{
          height: "48px",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.23)",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        }}
        value={amount}
      >
        <MenuItem value={amount}>20,000</MenuItem>
      </Select>
    </Box>
  );
};

export const JuniorCredits = () => {
  const val = SubscriptionService.determineCreditValue(UserSubscription.JUNIOR);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <FormLabel>Credit amount</FormLabel>
      <Select
        variant="outlined"
        fullWidth
        disabled
        label=""
        sx={{
          height: "48px",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.23)",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        }}
        value={val}
      >
        <MenuItem value={val}>3,000</MenuItem>
      </Select>
    </Stack>
  );
};
export const EnterpriseCredits = () => {
  const val = SubscriptionService.determineCreditValue(UserSubscription.ENTERPRISE);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <FormLabel>Credit amount</FormLabel>
      <Select
        variant="outlined"
        fullWidth
        disabled
        label=""
        sx={{
          height: "48px",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.23)",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        }}
        value={val}
      >
        <MenuItem value={val}>30,000</MenuItem>
      </Select>
    </Stack>
  );
};

export const CardIconBg = styled("div")(({ theme }) => ({
  width: 48,
  minWidth: 48,
  height: 48,
  padding: 3,
  borderRadius: 8,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.tertiary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const GetStartedBlock = ({
  handleGetStarted,
  isCurrentPlan,
  handleTalkToSales,
  loading,
  disabled,
}: {
  handleGetStarted: () => void;
  handleTalkToSales: () => void;
  loading?: boolean;
  disabled?: boolean;
  isCurrentPlan?: boolean;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
      width: "100%",
    }}
  >
    <LoadingButton
      disabled={(disabled || isCurrentPlan) ?? false}
      loading={loading ?? false}
      variant="contained"
      fullWidth
      sx={{
        height: "48px",
      }}
      onClick={handleGetStarted}
    >
      {isCurrentPlan ? "Current plan" : "Get started"}
    </LoadingButton>
    <Button
      onClick={handleTalkToSales}
      variant="outlined"
      fullWidth
      sx={{
        background: (theme) => theme.palette.common.white,
        height: "48px",
      }}
    >
      Chat to sales
    </Button>
  </Box>
);

export type SubscriptionCardProps = {
  userPlan?: UserSubscription | null;
  subscription: Subscription;
  onFreeTrial?: boolean;
  loading?: boolean;
  expanded?: boolean;
  popular?: boolean;
  disabled?: boolean;
  onViewMore?: () => void;
  onClickGetStarted: (priceId: string, productId: string, phonePriceId: string, emailPriceId: string) => void;
  onClickTalkToSales: (priceId: string, productId: string) => void;
};
const isCurrentPlan = (
  internalPlan: UserSubscription | null = "FREEMIUM",
  userPlan: UserSubscription | null = "FREEMIUM",
) => {
  return internalPlan === userPlan;
};

export const formatDecimalToCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value / 100);

export const SubscriptionCard = ({
  userPlan,
  loading,
  disabled,
  onClickGetStarted,
  onFreeTrial,
  popular,
  expanded,
  onViewMore,
  subscription: { id, priceId, productId, phonePriceId, emailPriceId, numberOfUsers },
}: SubscriptionCardProps) => {
  const [internalPlan, setInternalPlan] = useState<UserSubscription | null>(null);

  const { data: priceData, isPending: isLoadingPriceData } = api.settings.getStripePlan.useQuery(
    {
      priceId: priceId,
      productId: productId,
    },
    {
      enabled: !!priceId && !!productId,
    },
  );

  useEffect(() => {
    if (id === userPlan) {
      setInternalPlan(userPlan);
    }
  }, [userPlan, id]);

  const price = priceData?.price;

  const handleGetStarted = () => {
    onClickGetStarted(priceId, productId, phonePriceId, emailPriceId);
  };

  const handleTalkToSales = () => {
    void window.open("https://meetings-eu1.hubspot.com/max-mitcham/round-robin", "_blank");
  };

  return (
    <Card
      variant="elevation"
      elevation={0}
      sx={{
        background: (theme) => (popular ? theme.palette.background.default : theme.palette.background.paper),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 4,
        height: "100%",
        gap: 2,
        position: "relative",
        borderRadius: 4,
        // width: 400,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            gap: 1,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="body1">Number of users</Typography>
          <Typography variant="caption">{numberOfUsers}</Typography>
        </Stack>
        {isLoadingPriceData ? (
          <Skeleton variant="text" width="100%" height={50} />
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontSize: "36px",
              fontStyle: "normal",
              color: (theme) => theme.palette.text.primary,
              fontWeight: 600,
              lineHeight: "44px" /* 122.222% */,
              letterSpacing: "-0.72px",
            }}
          >
            {id === process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID
              ? "Custom"
              : formatDecimalToCurrency(price?.unit_amount!)}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <GetStartedBlock
            handleGetStarted={handleGetStarted}
            handleTalkToSales={handleTalkToSales}
            loading={loading || isLoadingPriceData}
            disabled={disabled || isLoadingPriceData}
            isCurrentPlan={isCurrentPlan(internalPlan, userPlan)}
          />

          <Button
            variant="text"
            onClick={onViewMore}
            endIcon={<ArrowNarrowDown width={16} style={{ transform: expanded ? "rotate(180deg)" : "" }} />}
          >
            {expanded ? "Hide details" : "View details"}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
