type CustomEventsDefinition = {
  openUpgradeDialog: {
    message: string;
  };
};

type CustomEvents = keyof CustomEventsDefinition;

export function publishEvent<T extends CustomEvents>(eventName: T, payload?: CustomEventsDefinition[T]): void {
  const event = payload ? new CustomEvent(eventName, { detail: payload }) : new CustomEvent(eventName);
  window.dispatchEvent(event);
}

type Unsubscribe = () => void;

function isCustomEvent(event: Event): event is CustomEvent {
  return "detail" in event;
}

export function subscribeToEvent<T extends CustomEvents>(
  eventName: T,
  handlerFn: (payload: CustomEventsDefinition[T]) => void,
): Unsubscribe {
  const eventHandler = (event: Event) => {
    if (isCustomEvent(event)) {
      const eventPayload: CustomEventsDefinition[T] = event.detail;
      handlerFn(eventPayload);
    }
  };
  window.addEventListener(eventName, eventHandler);
  return () => {
    window.removeEventListener(eventName, eventHandler);
  };
}
